// webpack requires to use this file for import links for SEO
const serviceId = {
	INTEGRATED_FACILITY_MAINTENANCE: 'integrated-facility-maintenance',
	MECHANICAL_PIPING: 'mechanical-piping',
	TANK_CONSTRUCTION: 'tank-construction',
	TANK_MAINTENANCE_REPAIR: 'tank-maintenance-repair',
	CIVIL_CONSTRUCTION: 'civil-construction',
	SUPPORTING_SERVICES: 'supporting-services'
};

const articleId = {
	PROVISION_OF_MECHANICAL_AND_PIPING: 'provision-of-mechanical-and-piping',
	ATMOSPHERIC_TANK: 'atmospheric-tank',
	SPHERICAL_TANK: 'spherical-tank'
};

const caseId = {
	GRAB_CASE: 'grab-case',
	UEX_CASE: 'uex-case'
};

const servicesParent = '/services';
const articlesParent = '/articles';

const paths = {
	HOME: '/',
	INTEGRATED_FACILITY_MAINTENANCE: `${servicesParent}/${serviceId.INTEGRATED_FACILITY_MAINTENANCE}`,
	MECHANICAL_PIPING: `${servicesParent}/${serviceId.MECHANICAL_PIPING}`,
	TANK_CONSTRUCTION: `${servicesParent}/${serviceId.TANK_CONSTRUCTION}`,
	TANK_MAINTENANCE_REPAIR: `${servicesParent}/${serviceId.TANK_MAINTENANCE_REPAIR}`,
	CIVIL_CONSTRUCTION: `${servicesParent}/${serviceId.CIVIL_CONSTRUCTION}`,
	SUPPORTING_SERVICES: `${servicesParent}/${serviceId.SUPPORTING_SERVICES}`,
	ARTICLES: articlesParent,
	PROVISION_OF_MECHANICAL_AND_PIPING: `${articlesParent}/${articleId.PROVISION_OF_MECHANICAL_AND_PIPING}`,
	ATMOSPHERIC_TANK: `${articlesParent}/${articleId.ATMOSPHERIC_TANK}`,
	SPHERICAL_TANK: `${articlesParent}/${articleId.SPHERICAL_TANK}`,
	ABOUT: '/about',
	JOBS: '/jobs',
	TERMS: '/terms',
	PRIVACY_POLICY: '/privacy-policy'
};

/* eslint-disable */
// Helper function to get all links for SEO
function getLinksArr() {
	let linksArr = [];
	for (let i in paths) {
		linksArr.push(paths[i]);
	};
	return linksArr;
};

export {
	serviceId,
	articleId,
	caseId,
	paths,
	getLinksArr
};
