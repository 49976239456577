import { articleId, paths } from '../../sources/constant';

import atmosphericTankImage from '../../assets/images/atmosphericTank/persafe-tank-cleaning-1.jpg';
import anttiProfileImage from '../../assets/images/antti-thumbnail.jpg';
import atmospherticTank1 from '../../assets/images/atmosphericTank/athmospheric-1.jpg';
import atmospherticTank2 from '../../assets/images/atmosphericTank/athmospheric-2.jpg';

export default {
	id: articleId.ATMOSPHERIC_TANK,
	image: atmosphericTankImage,
	imageAlt: 'Atmospheric Tank Services',
	author: 'Persafe',
	authorImage: anttiProfileImage,
	date: '24 June 2024',
	href: paths.ATMOSPHERIC_TANK,
	en: {
		title: {
			first: 'Master Service Agreement',
			text: 'For Atmospheric Storage Tank Cleaning And Maintenance Services',
			translate: false
		},
		thumbnailTitle: 'Atmospheric Storage Tank Cleaning and Maintenance Services',
		thumbnailDescription: 'Delivering expert desludging, cleaning, blasting, painting, and repair services for PETRONAS under a Master Service Agreement (MSA).',
		content: [{
			html: ` <h4 class="page-title">Master Service Agreement (MSA) For Atmospheric Storage Tank Cleaning And Maintenance Services</h4>
                    <p>Persafe Engineering is proud to partner with PETRONAS under a Master Service Agreement (MSA) to provide comprehensive cleaning and maintenance services for atmospheric storage tanks. Our services ensure the integrity and longevity of storage tanks through meticulous desludging, cleaning, blasting, painting, and repair works.</p>
                    
                    <h5 class="subtitle">Project Overview</h5>
                    <p>The Master Service Agreement (MSA) for Atmospheric Storage Tank Cleaning and Maintenance Services encompasses a range of activities aimed at maintaining and enhancing the functionality of storage tanks. This project involves desludging and cleaning of storage tanks, blasting and painting both internal and external surfaces, and performing essential repairs on tank shells, bottom plates, tank roofs, and associated piping. Our team’s expertise and commitment to quality ensure the seamless execution of these tasks, contributing to the operational reliability and safety of PETRONAS’s storage facilities.</p>
					<div class="row img-wrapper">
                        <div class="col-12 col-lg-11 mx-auto">
                            <p><img src=${atmospherticTank1} alt="Tank Cleaning, Blasting, and Painting" class="img-fluid"/></p>
                        </div>
                    </div>
                    
                    <h5 class="subtitle">Tank Cleaning, Blasting, and Painting</h5>
                    <p>A critical part of this project is the comprehensive cleaning, blasting, and painting of storage tanks. These tasks involve removing sludge, blasting surfaces to remove rust and contaminants, and applying protective coatings both internally and externally. Our team ensures that each tank is thoroughly cleaned and coated to prevent corrosion and extend its service life.</p>

                    <h5 class="subtitle">Tank Bottom Plate Replacement</h5>
                    <p>Replacing the bottom plates of storage tanks is essential for maintaining their structural integrity. This task involves removing the old, damaged plates and installing new ones with precision. Our skilled technicians carry out this process efficiently, ensuring that the tanks are restored to optimal condition.</p>
                    
                    <div class="row img-wrapper">
                        <div class="col-12 col-lg-11 mx-auto">
                            <p><img src=${atmospherticTank2} alt="Tank Bottom Plate Replacement" class="img-fluid"/></p>
                        </div>
                    </div>

                    <h5 class="subtitle">Conclusion</h5>
                    <p>The successful execution of these services under the MSA with PETRONAS highlights Persafe Engineering’s capabilities in delivering high-quality tank maintenance and repair services. Our commitment to safety, efficiency, and excellence continues to drive our success in the oil and gas industry.</p>

                    <p>At Persafe, we thrive on collaboration, whether for local or international projects. No project is too small or too large for us to consider. We approach every opportunity with dedication and enthusiasm. For potential tenders or collaboration inquiries, please contact us.</p>`
		}]
	}
};
