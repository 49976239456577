import '../styles/Carousel.scss';
import '../styles/Testimonials.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import Banner from '../components/Banner';

const TestimonialsComponent = ({ testimonialList = [] }) => {
	const settings = {
		dots: testimonialList.length > 1,  // Show dots only if there’s more than 1 testimonial
		infinite: testimonialList.length > 1,  // Enable infinite scrolling only if there’s more than 1 testimonial
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: testimonialList.length > 1,  // Show arrows only if there’s more than 1 testimonial
		autoplay: false,
	};

	// console.log("TestimonialList:")
	// console.log(testimonialList)

	return (
		<div id="testimonials" className="testimonials">
			{/*
			<div className="col-md-8 mx-auto black">
				<h4 className="page-title">
					<FormattedMessage id="testimonials.title" />
				</h4>
			</div>
			*/}
			<Banner className="slides">
				<Slider className="carousel mx-auto mb-5" {...settings}>
					{testimonialList.map(testimonial => (
						<div key={testimonial.id} className="postit-wrapper col-md-8">
							<div className="postit-container">
								<p className="postit-content">{testimonial.content}</p>
								<p className="postit-author">
									<small>
										<FormattedMessage
											id="testimonials.author"
											values={{
												name: testimonial.name,
												position: testimonial.position,
												organisation: testimonial.organisation,
											}}
										/>
									</small>
								</p>
							</div>

							<div className="testimonials-quote-icon">
								<i className="fa fa-quote-right" />
							</div>
						</div>
					))}
				</Slider>
			</Banner>
		</div>
	);
};

TestimonialsComponent.propTypes = {
	testimonialList: PropTypes.arrayOf(PropTypes.object),
};

export default TestimonialsComponent;
