import { serviceId } from '../sources/constant';

import integratedFacilityMaintenanceImage1 from '../assets/images/provisionOfMechanicalAndPiping/persafe-shutdown-2.jpg';
import mechanicalPipingImage1 from '../assets/images/persafe/mechanical-piping-1.jpeg';
import mechanicalPipingImage2 from '../assets/images/persafe/mechanical-piping-2.jpeg';
import mechanicalPipingImage3 from '../assets/images/persafe/mechanical-piping-vessel-cleaning.jpeg';
import shutdownTurnaroundImage1 from '../assets/images/persafe/ifm-shutdown-turnaround.jpeg';
import structuralFabricationInstallationImage1 from '../assets/images/persafe/structural-fabrication-installation-2.png';
import tankConstructionImage1 from '../assets/images/persafe/tank-construction-1.jpeg';
import tankMaintenanceRepairImage1 from '../assets/images/persafe/tank-maintenance-1.jpeg';
import civilConstructionImage1 from '../assets/images/persafe/persafe-civil-1-0.png';
import civilConstructionImage2 from '../assets/images/persafe/persafe-civil-2-0.png';
import civilConstructionImage3 from '../assets/images/persafe/persafe-civil-3-laying-surface.png';
import supportingServicesImage1 from '../assets/images/persafe/supp-conventional-scaffold.jpg';
import supportingServicesImage2 from '../assets/images/persafe/supp-system-scaffold.jpg';

/*
	{@format} - localisation
	{@slider} - convert to carousel
				use '$' to seperate between slides
*/
const serviceDescriptionList = [{
	id: serviceId.INTEGRATED_FACILITY_MAINTENANCE,
	intro: {
		title: 'serviceDescription.integratedFacilityMaintenance.intro.title',
		text: 'serviceDescription.integratedFacilityMaintenance.intro.text'
	},
	content: [{
		html: `<div>
					<section>
						<h4 class="service-title">{@format: serviceDescription.integratedFacilityMaintenance.content1.title1}</h4>
						<p>
							{@format: serviceDescription.integratedFacilityMaintenance.content1.text1}
						</p>
						<p>
							{@format: serviceDescription.integratedFacilityMaintenance.content1.text2}
						</p>
						<p class="service-img-wrapper">
							<img src=${integratedFacilityMaintenanceImage1} alt="{@format: serviceDescription.integratedFacilityMaintenance.content1.imgAlt}"/>
						</p>
						<h4 class="service-title">{@format: serviceDescription.integratedFacilityMaintenance.content2.title1}</h4>
						<p>
							{@format: serviceDescription.integratedFacilityMaintenance.content2.text1}
						</p>
						<h5 class="service-title">{@format: serviceDescription.integratedFacilityMaintenance.content2.title2}</h5>
						<p>
							{@format: serviceDescription.integratedFacilityMaintenance.content2.text2}
						</p>
						<p>
							{@format: serviceDescription.integratedFacilityMaintenance.content2.text3}
						</p>
						<p class="service-img-wrapper">
							<img src=${shutdownTurnaroundImage1} alt="{@format: serviceDescription.integratedFacilityMaintenance.content2.imgAlt}"/>
						</p>
					</section>
				</div>`
	}]
}, {
	id: serviceId.MECHANICAL_PIPING,
	intro: {
		title: 'serviceDescription.mechanicalPiping.intro.title',
		text: 'serviceDescription.mechanicalPiping.intro.text'
	},
	content: [{
		html: `<div>
					<section>
						<h4 class="service-title">{@format: serviceDescription.mechanicalPiping.content1.title1}</h4>
						<p>
							{@format: serviceDescription.mechanicalPiping.content1.text1}
						</p>
						<p>
							{@format: serviceDescription.mechanicalPiping.content1.text2}
						</p>
						<p class="service-img-wrapper">
							<h4>
								{@format: serviceDescription.mechanicalPiping.content1.imageHeader}
							</h4>
							<img src=${mechanicalPipingImage1} alt="{@format: serviceDescription.supportingServices.content2.imgAlt}"/>
						</p>
						<p class="service-img-wrapper">
							<h4>
								{@format: serviceDescription.mechanicalPiping.content2.imageHeader}
							</h4>
							<img src=${mechanicalPipingImage2} alt="{@format: serviceDescription.supportingServices.content2.imgAlt}"/>
						</p>
						<p class="service-img-wrapper">
							<h4>
								{@format: serviceDescription.mechanicalPiping.content3.imageHeader}
							</h4>
							<img src=${mechanicalPipingImage3} alt="{@format: serviceDescription.supportingServices.content2.imgAlt}"/>
						</p>
					</section>
				</div>`
	}]
}, {
	id: serviceId.STRUCTURAL_FABRICATION_INSTALLATION,
	intro: {
		title: 'serviceDescription.structuralFabricationInstallation.intro.title',
		text: 'serviceDescription.structuralFabricationInstallation.intro.text'
	},
	content: [{
		html: `<div>
					<section>
						<h6 class="service-title">{@format: serviceDescription.structuralFabricationInstallation.content1.title1}</h6>
						<p>
							{@format: serviceDescription.structuralFabricationInstallation.content1.text1}
						</p>
						<p>
							{@format: serviceDescription.structuralFabricationInstallation.content1.text2}
						</p>
						<p class="service-img-wrapper">
							<img src=${structuralFabricationInstallationImage1} alt="{@format: serviceDescription.structuralFabricationInstallation.content1.imgAlt}"/>
						</p>
					</section>
				</div>`
	}]
}, {
	id: serviceId.TANK_CONSTRUCTION,
	intro: {
		title: 'serviceDescription.tankConstruction.intro.title',
		text: 'serviceDescription.tankConstruction.intro.text'
	},
	content: [{
		html: `<div>
					<section>
						<h6 class="service-title">{@format: serviceDescription.tankConstruction.content1.title1}</h6>
						<p>
							{@format: serviceDescription.tankConstruction.content1.text1}
						</p>
						<p>
							{@format: serviceDescription.tankConstruction.content1.text2}
						</p>
						<p class="service-img-wrapper">
							<img src=${tankConstructionImage1} alt="{@format: serviceDescription.tankConstruction.content1.imgAlt}"/>
						</p>
					</section>
				</div>`
	}]
}, {
	id: serviceId.TANK_MAINTENANCE_REPAIR,
	intro: {
		title: 'serviceDescription.tankMaintenanceRepair.intro.title',
		text: 'serviceDescription.tankMaintenanceRepair.intro.text'
	},
	content: [{
		html: `<div>
					<section>
						<h6 class="service-title">{@format: serviceDescription.tankMaintenanceRepair.content1.title1}</h6>
						<p>
							{@format: serviceDescription.tankMaintenanceRepair.content1.text1}
						</p>
						<p>
							{@format: serviceDescription.tankMaintenanceRepair.content1.text2}
						</p>
						<p class="service-img-wrapper">
							<img src=${tankMaintenanceRepairImage1} alt="{@format: serviceDescription.tankMaintenanceRepair.content1.imgAlt}"/>
						</p>
					</section>
				</div>`
	}]
}, {
	id: serviceId.CIVIL_CONSTRUCTION,
	intro: {
		title: 'serviceDescription.civilConstruction.intro.title',
		text: 'serviceDescription.civilConstruction.intro.text'
	},
	content: [{
		html: `<div>
					<section>
						<h6 class="service-title">{@format: serviceDescription.civilConstruction.content1.title1}</h6>
						<p>
							{@format: serviceDescription.civilConstruction.content1.text1}
						</p>
						<p>
							{@format: serviceDescription.civilConstruction.content1.text2}
						</p>
						<p class="service-img-wrapper">
							<h4>
								{@format: serviceDescription.civilConstruction.content1.imageHeader}
							</h4>
							<img src=${civilConstructionImage1} alt="{@format: serviceDescription.civilConstruction.content1.imgAlt}"/>
						</p>
						<p class="service-img-wrapper">
							<h4>
								{@format: serviceDescription.civilConstruction.content2.imageHeader}
							</h4>
							<img src=${civilConstructionImage2} alt="{@format: serviceDescription.civilConstruction.content2.imgAlt}"/>
						</p>
						<p class="service-img-wrapper">
							<h4>
								{@format: serviceDescription.civilConstruction.content3.imageHeader}
							</h4>
							<img src=${civilConstructionImage3} alt="{@format: serviceDescription.civilConstruction.content3.imgAlt}"/>
						</p>
					</section>
				</div>`
	}]
}, {
	id: serviceId.SUPPORTING_SERVICES,
	intro: {
		title: 'serviceDescription.supportingServices.intro.title',
		text: 'serviceDescription.supportingServices.intro.text'
	},
	content: [{
		html: `<div>
					<section>
						<h4 class="service-title">{@format: serviceDescription.supportingServices.content1.title}</h4>
						<p>
							{@format: serviceDescription.supportingServices.content1.text1}
						</p>
						<p>
							{@format: serviceDescription.supportingServices.content1.text2}
						</p>
						<p class="service-img-wrapper">
							<h4>
								{@format: serviceDescription.supportingServices.content1.imageHeader}
							</h4>
							<img src=${supportingServicesImage1} alt="{@format: serviceDescription.supportingServices.content1.imgAlt}"/>
						</p>
						<p class="service-img-wrapper">
							<h4>
								{@format: serviceDescription.supportingServices.content2.imageHeader}
							</h4>
							<img src=${supportingServicesImage2} alt="{@format: serviceDescription.supportingServices.content2.imgAlt}"/>
						</p>
					</section>
					<section>
						<h4 class="service-title">{@format: serviceDescription.supportingServices.content2.title}</h4>
						<p>
							{@format: serviceDescription.supportingServices.content2.text1}
						</p>
						<p>
							{@format: serviceDescription.supportingServices.content2.text2}
						</p>
					</section>
					<section>
						<h4 class="service-title">{@format: serviceDescription.supportingServices.content3.title}</h4>
						<p>
							{@format: serviceDescription.supportingServices.content3.text1}
						</p>
						<p>
							{@format: serviceDescription.supportingServices.content3.text2}
						</p>
					</section>
				</div>`
	}]
}];

export default serviceDescriptionList;
