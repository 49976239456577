import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

const AnimationComponent = ({ animate, children, once = false }) => {
    const [animation, setAnimation] = useState(false);
    const [disable, setDisable] = useState(false);
    const [style, setStyle] = useState({});

    useEffect(() => {
        if (animate === 'fadeIn') {
            setStyle({
                opacity: 1, // placeholder for fixed opecity 1 - original was 0 (start to fade in)
                height: '100%'
            });
        }
    }, [animate]);
    
    const addAnimation = useCallback(() => {
        // console.log('onEnter - Adding animation, once:', once);
        setAnimation(true);
        setDisable(once);
    }, [once]);
    
    const removeAnimation = useCallback(() => {
        // console.log('onLeave - Removing animation');
        setAnimation(false);
        // setStyle({ opacity: 0, height: '100%' }); // Reset if leaving
    }, []);
    
    const toggleAnimation = () => {
        // const result = animation ? animate : '';
        // console.log('toggleAnimation - Animation state:', animation, 'Result:', result);
        return animation ? animate : '';
    };
    
    return (
        <Waypoint
            onEnter={!disable ? addAnimation : undefined}
            onLeave={!once ? removeAnimation : undefined}
        >
            <div className={`animated ${toggleAnimation()}`} style={style}>
                {children}
            </div>
        </Waypoint>
    );
};

AnimationComponent.propTypes = {
    animate: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    once: PropTypes.bool
};

export default AnimationComponent;
