import '../styles/Articles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import HeroBanner from '../components/HeroBanner';
import ArticleCard from '../components/ArticleCard';

import ExtendNavbar from '../components/ExtendNavbar';

import articleImg from '../assets/images/persafe/projects-people.jpg';

const content = {
	image: articleImg,
	title: {
		first: 'articles.title.first',
		text: 'articles.title.text'
	}
};

class ArticlesPage extends React.Component {
	componentDidMount() {
		const { fetchArticles } = this.props;

		fetchArticles();
	}

	render() {
		const { articleList } = this.props;
		return (
			<div className="articles">

				{/* Articles Hero */}
				<HeroBanner content={content} />

				{/* Trigger Navbar Extension */}
				<ExtendNavbar />

				{/* Article List */}
				<div className="intro-overlap col-12 col-lg-9 mx-auto">
					<div className="intro-content">
						<div className="col-md-11 mx-auto">
							<h4 className="page-title">
								<FormattedMessage
									id="articles.content.title"
								/>
							</h4>
						</div>
					</div>
					<div className="articles-wrapper">
						<div className="row">
							{articleList && articleList.map(article => (
								<div className="col-12 col-md-6 col-lg-4 article" key={article.id}>
									<ArticleCard content={article} />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ArticlesPage.propTypes = {
	fetchArticles: PropTypes.func.isRequired,
	articleList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ArticlesPage;
