import _ from 'lodash';

import ProvisionOfMechanicalAndPiping from '../../sources/articles/provisionOfMechanicalAndPiping';
import AtmosphericTank from '../../sources/articles/atmosphericTank';
import EpccTank from '../../sources/articles/epccTank';
// import HarnessingGenerativeAI from '@/sources/articles/harnessingGenerativeAI';
// import Snowflake from '@/sources/articles/snowflake';
// import DigitalOutreachPlatform from '@/sources/articles/digitalOutreachPlatform';
// import PartnerWithShareShare from '@/sources/articles/partnerWithShareShare';
// import LightBulb from '@/sources/articles/lightBulb';
// import GrabCase from '@/sources/articles/grabCase';
// import UEXCase from '@/sources/articles/uexCase';

// Add new articles to this list
const articles = [
	ProvisionOfMechanicalAndPiping,
	AtmosphericTank,
	EpccTank // ,
	// HarnessingGenerativeAI,
	// Snowflake,
	// DigitalOutreachPlatform,
	// PartnerWithShareShare,
	// LightBulb
];

function getArticleList(locale) {
	const list = [];
	_.each(articles, (article) => {
		const {
			id, image, imageAlt, author, authorImage, date, href
		} = article;
		const {
			thumbnailTitle, thumbnailDescription
		} = article[locale];
		list.push({
			id, image, imageAlt, thumbnailTitle, thumbnailDescription, author, authorImage, date, href
		});
	});
	return list;
}

function getArticleContentList(locale) {
	const contentList = [];
	_.each(articles, (article) => {
		const { id, image } = article;
		const { title, content } = article[locale];
		contentList.push({
			id, image, title, content
		});
	});
	return contentList;
}

export {
	getArticleList,
	getArticleContentList
};
