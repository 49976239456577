import './styles/__index.scss';

import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';
import Routes from './Routes';
import configureStore from './stores';
import localeData from './locales/data.json';
import reportWebVitals from './reportWebVitals';

// Configure the store
const store = configureStore();

// Determine the user's language preference
const language = (navigator.languages && navigator.languages[0]) 
    || navigator.language 
    || navigator.userLanguage;

// Fallback to 'en' if the user's preferred language isn't supported
const locale = language.split(/[-_]/)[0]; // e.g., 'en-US' => 'en'
const messages = localeData[locale] || localeData['en']; // Fallback to English if the locale isn't found
// console.log('index.js=Locale:', locale);
// console.log('index.js=Messages:', messages);

// Component to set up and provide the Intl context
const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Dispatch an action to set the locale in the Redux store
        dispatch({
            type: 'SET_LOCALE',
            payload: { locale, messages }
        });
    }, [dispatch]);

    return (
        <IntlProvider locale={locale} messages={messages}>
            <Routes />
        </IntlProvider>
    );
};

// Render the root component
const container = document.getElementById('root'); // Ensure the container matches the ID in public/index.html
const root = createRoot(container); // Create a root.

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals(console.log);
