import { serviceId } from '../sources/constant';

import image6 from '../assets/images/persafe/ifm-hero.jpg';
import image7 from '../assets/images/persafe/mechanical-piping-hero.jpeg';
import image8 from '../assets/images/persafe/tank-construction-hero.jpeg';
import image10 from '../assets/images/persafe/tank-maintenance-hero.jpeg';
// import image11 from '@/assets/images/persafe/civil-construction-1.png';
import image11 from '../assets/images/persafe/persafe-road-construction.jpg';
import image12 from '../assets/images/persafe/persafe-one-stop-centre.jpg';

const serviceList = [{
	id: serviceId.INTEGRATED_FACILITY_MAINTENANCE,
	title: {
		first: 'serviceOffering.integrated',
		text: 'serviceOffering.facilityMaintenance'
	},
	description: 'serviceOffering.integratedFacilityMaintenanceDescription',
	image: image6,
	imageAlt: 'Integrated Facility Maintenance',
	link: {
		text: 'serviceOffering.integratedFacilityMaintenancelink'
	}
}, {
	id: serviceId.MECHANICAL_PIPING,
	title: {
		first: 'serviceOffering.mechanical',
		text: 'serviceOffering.piping'
	},
	description: 'serviceOffering.mechanicalPipingDescription',
	image: image7,
	imageAlt: 'Mechanical Piping',
	link: {
		text: 'serviceOffering.mechanicalPipinglink'
	}
}, {
	id: serviceId.TANK_CONSTRUCTION,
	title: {
		first: 'serviceOffering.tankConstruction-p1',
		text: 'serviceOffering.tankConstruction-p2'
	},
	description: 'serviceOffering.tankConstructionDescription',
	image: image8,
	imageAlt: 'Tank Maintenance & Repair',
	link: {
		text: 'serviceOffering.tankConstructionlink'
	}
}, {
	id: serviceId.TANK_MAINTENANCE_REPAIR,
	title: {
		first: 'serviceOffering.tankMaintenance',
		text: 'serviceOffering.repair'
	},
	description: 'serviceOffering.tankMaintenanceRepairDescription',
	image: image10,
	imageAlt: 'Tank Maintenance & Repair',
	link: {
		text: 'serviceOffering.tankMaintenanceRepairlink'
	}
}, {
	id: serviceId.CIVIL_CONSTRUCTION,
	title: {
		first: 'serviceOffering.civil',
		text: 'serviceOffering.construction'
	},
	description: 'serviceOffering.civilConstructionDescription',
	image: image11,
	imageAlt: 'Civil Construction',
	link: {
		text: 'serviceOffering.civilConstructionlink'
	}
}, {
	id: serviceId.SUPPORTING_SERVICES,
	title: {
		first: 'serviceOffering.supporting',
		text: 'serviceOffering.services'
	},
	description: 'serviceOffering.supportingServicesDescription',
	image: image12,
	imageAlt: 'Supporting Services',
	link: {
		text: 'serviceOffering.supportingServiceslink'
	}

}];

export default serviceList;
