import { paths } from '../sources/constant';

const navList = [{
	id: 'services',
	title: 'nav.services',
	subLinks: [{
		title: 'nav.integratedFacilityMaintenance',
		to: paths.INTEGRATED_FACILITY_MAINTENANCE
	}, {
		title: 'nav.mechanicalPiping',
		to: paths.MECHANICAL_PIPING
	}, {
		title: 'nav.tankConstruction',
		to: paths.TANK_CONSTRUCTION
	}, {
		title: 'nav.tankMaintenanceRepair',
		to: paths.TANK_MAINTENANCE_REPAIR
	}, {
		title: 'nav.civilConstruction',
		to: paths.CIVIL_CONSTRUCTION
	}, {
		title: 'nav.supportingServices',
		to: paths.SUPPORTING_SERVICES
	}]
}, {
	id: 'articles',
	title: 'nav.articles',
	to: paths.ARTICLES
}, {
	id: 'about',
	title: 'nav.about',
	to: paths.ABOUT
}, {
	id: 'jobs',
	title: 'nav.jobs',
	to: paths.JOBS
},
{
	id: 'terms',
	title: 'nav.terms',
	to: paths.TERMS,
	hide: true
}, {
	id: 'privacy-policy',
	title: 'nav.privacyPolicy',
	to: paths.PRIVACY_POLICY,
	hide: true
},
{
	id: 'contact',
	title: 'nav.contact',
	anchor: true,
	to: 'contactForm'
}
];

export default navList;
