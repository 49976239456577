import '../styles/AboutContent.scss';

import React from 'react';
// import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import imageSrc1 from '../assets/images/persafe/persafe-one-stop-centre-2.jpg';
import imageSrc2 from '../assets/images/persafe/ISO-9001-2015-UKAS-purple-c.jpg';
import imageSrc3 from '../assets/images/persafe/ISO-45001-2018-Malaysia-01-c.jpg';
import imageSrc4 from '../assets/images/persafe/about-us-2.jpg';
import imageSrc5 from '../assets/images/persafe/persafe-yard-facilities.jpg';
import imageSrc6 from '../assets/images/persafe/persafe-key-values.jpg';
import imageSrc7 from '../assets/images/persafe/about-us-daily-tbm.jpg';

// import slide4 from '@/assets/images/transform-build-product.png';

function AboutContentComponent() {
	return (
		<div className="about-container intro-overlap col-12 col-lg-9 mx-auto">
			<div className="intro-content">
				<div className="col-md-11 mx-auto">
					<h4 className="page-title">
						<FormattedMessage
							id="about.intro.title"
						/>
					</h4>
					<p>
						<FormattedMessage
							id="about.intro.text1"
						/>
					</p>
					<p>
						<FormattedMessage
							id="about.intro.text2"
						/>
					</p>
				</div>
				<h3 className="col-12 col-md-9 h2 mx-auto about-quotes text-center">
					<FormattedMessage
						id="about.intro.quote"
					/>
				</h3>
				<div className="about-embed-image-wrapper">
					<img src={imageSrc1} alt="One Stop Centre" className="about-embed-image" />
				</div>
				{/*
				<div className="about-image row">
					<div className="col-11 col-md-6 text-center">
						<img src={slide4} alt="From transformation to first version" />
					</div>
				</div>
				*/}
				<section className="col-md-11 mx-auto">
					<h4 className="page-title">
						<FormattedMessage
							id="about.story.title"
						/>
					</h4>
					<div className="row">
						<div className="col-md-6 text-center">
							<img src={imageSrc2} alt="ISO 9001:2015 cert" className="about-logo-image" />
						</div>
						<div className="col-md-6 text-center">
							<img src={imageSrc3} alt="ISO 45001:2018 cert" className="about-logo-image" />
						</div>
					</div>
					<p>
						<FormattedMessage
							id="about.story.text"
						/>
					</p>
				</section>
				<section className="col-md-11 mx-auto">
					<h4 className="page-title">
						<FormattedMessage
							id="about.story.title2"
						/>
					</h4>
					<div className="about-embed-image-wrapper">
						<img src={imageSrc4} alt="Integrated Services" className="about-embed-image" />
					</div>
					<p>
						<FormattedMessage
							id="about.story.text2"
						/>
					</p>
				</section>
				<section className="col-md-11 mx-auto">
					<h4 className="page-title">
						<FormattedMessage
							id="about.story.title3"
						/>
					</h4>
					<div className="about-embed-image-wrapper">
						<img src={imageSrc5} alt="Yard Facilities" className="about-embed-image" />
					</div>
					<p>
						<FormattedMessage
							id="about.story.text3"
						/>
					</p>
				</section>
				<section className="col-md-11 mx-auto">
					<h4 className="page-title">
						<FormattedMessage
							id="about.story.title4"
						/>
					</h4>
					<div className="about-embed-image-wrapper">
						<img src={imageSrc6} alt="Key Values" className="about-embed-image" />
					</div>
					<div className="about-embed-image-wrapper">
						<img src={imageSrc7} alt="Key Values - daily tbm" className="about-embed-image" />
					</div>
					<p>
						<FormattedMessage
							id="about.story.text4"
						/>
					</p>
				</section>
			</div>
		</div>
	);
}

AboutContentComponent.propTypes = {
};

export default AboutContentComponent;
