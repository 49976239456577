import React from 'react';
import PropTypes from 'prop-types';

import HeroBanner from '../components/HeroBanner';
import AboutContent from '../components/AboutContent';
import Team from '../components/Team';
// import Gallery from '@/components/Gallery';

import ExtendNavbar from '../components/ExtendNavbar';

import image from '../assets/images/persafe/persafe-aerial.jpg';

const content = {
	image,
	title: {
		first: 'about.title.first',
		text: 'about.title.text'
	}
};

class AboutPage extends React.Component {
	componentDidMount() {
		const { fetchTeam } = this.props;

		fetchTeam();
	}

	render() {
		const { techTeam, advisoryTeam, businessTeam } = this.props;
		return (
			<div className="about">

				{/* About Hero */}
				<HeroBanner content={content} />

				{/* Trigger Navbar Extension */}
				<ExtendNavbar />

				{/* About Content */}
				<AboutContent />

				{/* Team Members */}
				<Team techTeam={techTeam} advisoryTeam={advisoryTeam} businessTeam={businessTeam} />

				{/* Gallery */}
				{/* <Gallery></Gallery> */}
			</div>
		);
	}
}

AboutPage.propTypes = {
	fetchTeam: PropTypes.func.isRequired,
	techTeam: PropTypes.arrayOf(PropTypes.object).isRequired,
	advisoryTeam: PropTypes.arrayOf(PropTypes.object).isRequired,
	businessTeam: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AboutPage;
