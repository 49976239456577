import { articleId, paths } from '../../sources/constant';

import provisionOfMechanicalAndPipingImage from '../../assets/images/provisionOfMechanicalAndPiping/persafe-shutdown-2.jpg';
import anttiProfileImage from '../../assets/images/antti-thumbnail.jpg';
import provisionOfMechanicalAndPipingFlare from '../../assets/images/provisionOfMechanicalAndPiping/piping-flare-2-edit.jpg';
import provisionOfMechanicalAndPipingShutdown from '../../assets/images/provisionOfMechanicalAndPiping/persafe-shutdown-1.jpg';
import provisionOfMechanicalAndPipingInspection from '../../assets/images/provisionOfMechanicalAndPiping/persafe-inspection-1.jpg';

export default {
	id: articleId.PROVISION_OF_MECHANICAL_AND_PIPING,
	image: provisionOfMechanicalAndPipingImage,
	imageAlt: 'Mechanical and Piping provision',
	author: 'Persafe',
	authorImage: anttiProfileImage,
	date: '24 June 2024',
	href: paths.PROVISION_OF_MECHANICAL_AND_PIPING,
	en: {
		title: {
			first: 'Provision Of',
			text: 'Mechanical & Piping Maintenance Services',
			translate: false
		},
		thumbnailTitle: 'Mechanical & Piping Maintenance Services',
		thumbnailDescription: 'Delivering comprehensive mechanical and piping maintenance, building maintenance, and onshore construction services for Sarawak Shell Berhad at Bintulu Integrated Facility.',
		content: [{
			html: `<h4 class="page-title">Provision Of Mechanical & Piping Maintenance Services</h4>
					<p>Persafe Engineering was contracted by Sarawak Shell Berhad (SSB) to deliver integrated plant maintenance, mechanical and piping works, building maintenance, construction, modification, and civil works at the Bintulu Integrated Facility (BIF). This project underscores our commitment to excellence in the oil and gas sector, ensuring operational efficiency and safety.</p>
					<h5 class="subtitle">Project Overview</h5>
					<p>The Provision of Mechanical & Piping Maintenance Services at the Bintulu Integrated Facility involved a comprehensive scope of work aimed at maintaining and enhancing the facility’s infrastructure. This included mechanical and piping maintenance, building maintenance, construction and modification, and various civil works. Our team’s expertise ensured the seamless execution of these tasks, contributing to the facility's operational reliability and efficiency.</p>
					<h5 class="subtitle">HP Flare X-6201 & X-6211 Demounting & Remounting</h5>
					<p>One of the critical activities involved the demounting and remounting of the HP Flare X-6201 and X-6211. This complex task required precise planning and execution to ensure the safety and functionality of the flare systems. Our skilled technicians successfully completed this operation, ensuring minimal downtime and optimal performance.</p>

					<div class="row img-wrapper">
						<div class="col-12 col-lg-11 mx-auto">
							<p><img src=${provisionOfMechanicalAndPipingFlare} alt="HP Flare Demounting & Remounting" class="img-fluid"/></p>
						</div>
					</div>

                    <h5 class="subtitle">Restoration of BIF 30-inch Loading</h5>
                    <p>Another significant task was the restoration of the 30-inch loading system at BIF. This involved detailed inspections, repairs, and testing to restore the system to its full operational capacity. Our team’s attention to detail and adherence to safety standards were key to the successful completion of this task.</p>

                    <h5 class="subtitle">BAGSF Total Shutdown</h5>
                    <p>The BAGSF Total Shutdown was a major project milestone, involving the complete shutdown of the facility for maintenance and upgrades. This included thorough inspections, cleaning, and necessary modifications to enhance the facility's performance. Our coordination and project management skills ensured a smooth and efficient shutdown process.</p>

                    <div class="row img-wrapper">
                        <div class="col-12 col-lg-11 mx-auto">
                            <p><img src=${provisionOfMechanicalAndPipingShutdown} alt="BAGSF Total Shutdown" class="img-fluid"/></p>
                        </div>
                    </div>

                    <h5 class="subtitle">Vessel Inspection and Cleaning</h5>
                    <p>Regular vessel inspection and cleaning were integral parts of the project. These activities were critical to maintaining the integrity and safety of the storage vessels. Our team utilized advanced techniques and equipment to perform these tasks, ensuring compliance with industry standards and extending the service life of the vessels.</p>

                    <div class="row img-wrapper">
                        <div class="col-12 col-lg-11 mx-auto">
                            <p><img src=${provisionOfMechanicalAndPipingInspection} alt="Vessel Inspection and Cleaning" class="img-fluid"/></p>
                        </div>
                    </div>

                <h5 class="subtitle">16” Effluent Line Change Out</h5>
                <p>A crucial part of the project was the 16” Effluent Line Change Out. This task required the removal of the old line and the installation of a new one, ensuring the efficiency and safety of the effluent disposal system. Our experienced team managed this process with precision, adhering to all safety and operational standards.</p>
                
                <h5 class="subtitle">Conclusion</h5>
                <p>The successful execution of these tasks at the Bintulu Integrated Facility highlights Persafe Engineering’s capabilities in delivering high-quality maintenance and construction services. Our commitment to safety, efficiency, and excellence continues to drive our success in the oil and gas industry.</p>

				<p>At Persafe, we thrive on collaboration, whether for local or international projects. No project is too small or too large for us to consider. We approach every opportunity with dedication and enthusiasm. For potential tenders or collaboration inquiries, please contact us.</p>`
		}]
	}
};
