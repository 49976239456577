import React from 'react';
import PropTypes from 'prop-types';
import {
	Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import NavAnchorLink from '../components/NavAnchorLink';
import NavLink from '../components/NavLink';

class NavMenuComponent extends React.Component {
	static getTopOffset(dropdownBtnBound) {
		const offsetBottom = dropdownBtnBound.bottom;
		const navbarElement = document.getElementById('navbar');
		const navbarBound = navbarElement && navbarElement.getBoundingClientRect();
		const navbarHeight = navbarBound && navbarBound.height;

		if (offsetBottom && navbarHeight && navbarHeight > offsetBottom) {
			return navbarHeight - offsetBottom;
		}
		return null;
	}

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);

		this.state = {
			isOpen: false,
			topOffset: null
		};
	}

	componentDidUpdate() {
		const { isOpen } = this.state;

		if (!isOpen) {
			const dropdownBtnElement = this.dropdownRef;
			const dropdownBtnBound = dropdownBtnElement && dropdownBtnElement.getBoundingClientRect();
			const offset = dropdownBtnBound && NavMenuComponent.getTopOffset(dropdownBtnBound);
			this.updateState(offset);
		}
	}

	updateState(offset) {
		const { topOffset } = this.state;

		if (offset && offset !== topOffset) {
			this.setState({
				topOffset: offset
			});
		}
	}

	toggle(open) {
		const { isOpen } = this.state;

		if (isOpen !== open) {
			this.setState({
				isOpen: _.isBoolean(open) ? open : !isOpen
			});
		}
	}

	render() {
		const { isOpen, topOffset } = this.state;
		const {
			title,
			to,
			subLinks,
			anchor
		} = this.props;

		if (subLinks) {
			return (
				<div ref={(c) => { this.dropdownRef = c; }}>
					<Dropdown isOpen={isOpen} toggle={this.toggle}>
						<DropdownToggle className="nav-link">
							<FormattedMessage
								id={title}
							/>
							{isOpen
								? <div className="dropdown-arrow" /> : null
							}
						</DropdownToggle>
						<DropdownMenu style={{ marginTop: topOffset }} end>
							<div className="dropdown-container">
								{subLinks.map((link, i) => {
									let settings = {};
									let child = null;
									if (link.to) {
										settings = {
											tag: link.anchor ? NavAnchorLink : NavLink,
											to: link.to,
											title: link.title,
											style: link.anchor ? { cursor: 'pointer' } : undefined // Add cursor for anchored items
										};
									} else if (link.header) {
										settings = {
											tag: 'span',
											header: true
										};
										child = (
											<FormattedMessage
												id={link.title}
											/>
										);
									}
									return (
										<div key={link.title}>
											{i !== 0 && link.header ? <DropdownItem divider /> : null}
											<DropdownItem
												className={`dropdown-level-${link.header ? '1' : '2'}`}
												{...settings}
												onClick={() => this.toggle(false)}
											>
												{child}
											</DropdownItem>
										</div>
									);
								})}
							</div>
						</DropdownMenu>
					</Dropdown>
				</div>
			);
		} if (anchor) {
			return (
				<NavAnchorLink className="btn nav-link" to={to} title={title} />
			);
		}
		return (
			<NavLink className="btn nav-link" to={to} title={title} />
		);
	}
}

NavMenuComponent.propTypes = {
	title: PropTypes.string.isRequired,
	to: PropTypes.string,
	subLinks: PropTypes.arrayOf(PropTypes.object),
	anchor: PropTypes.bool
};

NavMenuComponent.defaultProps = {
	to: null,
	subLinks: null,
	anchor: false
};

export default NavMenuComponent;
