import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-scroll';

import HeroBanner from '../components/HeroBanner';
import Gallery from '../components/Gallery';
// import JobReasons from '../components/JobReasons';
// import JobPositions from '../components/JobPositions';
import JobContact from '../components/JobContact';
import ExtendNavbar from '../components/ExtendNavbar';

import image from '../assets/images/persafe/persafe-join-us.jpg';

import gallery1 from '../assets/images/persafe/persafe-join-us-gallery-1.jpg';
import gallery2 from '../assets/images/persafe/persafe-join-us-gallery-2.jpg';

const content = {
	image,
	title: {
		first: 'jobs.title.first',
		text: 'jobs.title.text'
	}
};

const galleries = [gallery1, gallery2];
const jobsAnchor = 'jobsAnchor';

class JobsPage extends React.Component {
	componentDidMount() {
		const { fetchJobs } = this.props;

		fetchJobs();
	}

	render() {
		// const { jobList } = this.props;
		return (
			<div className="jobs">

				{/* Jobs Hero */}
				<HeroBanner content={content} />

				{/* Trigger Navbar Extension */}
				<ExtendNavbar />

				<div className="intro-overlap col-12 col-lg-9 mx-auto">
					<div className="intro-content">
						<div className="col-md-11 mx-auto">
							<h4 className="page-title">
								<FormattedMessage
									id="jobs.content.title"
								/>
								{' '}
								<Link
									to={jobsAnchor}
									offset={-100}
									smooth
								>
									<FormattedMessage
										id="jobs.content.link"
									/>
								</Link>
							</h4>
						</div>
					</div>
				</div>

				{/* Gallery */}
				<Gallery images={galleries} />

				{/* Job Reasons */}
				{/* <JobReasons /> */}

				{/* Anchor for jobs link */}
				<span id={jobsAnchor} />

				{/* Job Positions */}
				{/* <JobPositions list={jobList} /> */}

				{/* Job Contact */}
				<JobContact />
			</div>
		);
	}
}

JobsPage.propTypes = {
	fetchJobs: PropTypes.func.isRequired,
	jobList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default JobsPage;
