const constants = {
	smallestScreenSize: 320,
	smallerScreenSize: 480,
	smallScreenSize: 576,
	mediumScreenSize: 768,
	RECAPTCHA_SITE_KEY: '6LcJIxEqAAAAAAaK6gt9B_RqJbuto9lx_jD4Kn-s',
	EMAIL_JS: {
		SERVICE_ID: 'service_lcd64ws', // links to dtaltech2017@gmail.com as a connected service.
		TEMPLATE_ID: 'template_kbznaeb', // 'template routing to persafe email',
		USER_ID: 'nAfeKgbVSAsk2WQ23' // 'user_Ny0Pny6rkOQNKZnqiPVda'
	}
};

export default Object.freeze(Object.assign({}, constants));
