import { articleId, paths } from '../../sources/constant';

import epccTankImage from '../../assets/images/epccTank/persafe-epcc-3.png';
import anttiProfileImage from '../../assets/images/antti-thumbnail.jpg';
import epccDesignEngineering from '../../assets/images/epccTank/persafe-epcc-1.png';
import epccProcurementMaterials from '../../assets/images/epccTank/persafe-epcc-4.png';
import epccFabricationAssembly from '../../assets/images/epccTank/persafe-epcc-2.png';
import epccTestingCertification from '../../assets/images/epccTank/persafe-epcc-6.png';

export default {
	id: articleId.SPHERICAL_TANK,
	image: epccTankImage,
	imageAlt: 'EPCC Tank Construction',
	author: 'Persafe',
	authorImage: anttiProfileImage,
	date: '24 June 2024',
	href: paths.SPHERICAL_TANK,
	en: {
		title: {
			first: 'Construction of',
			text: 'Ethylene and Propylene Storage Spherical Tanks',
			translate: false
		},
		thumbnailTitle: 'Construction of Ethylene and Propylene Storage Tanks',
		thumbnailDescription: 'Delivering construction, certification, and assembly services for eight spherical tanks at the Steam Cracker Complex in RAPID Pengerang.',
		content: [{
			html: ` <h4 class="page-title">Construction of Ethylene and Propylene Storage Spherical Tanks (8 units) for Utility Steam Cracker Complex, RAPID Pengerang</h4>
                    <p>Persafe Engineering successfully delivered the construction of eight Ethylene and Propylene storage spherical tanks at the Steam Cracker Complex in RAPID Pengerang. This project included engineering, procurement, fabrication, assembly, and certification to ensure compliance with ASME and DOSH standards.</p>
                    <h5 class="subtitle">Project Overview</h5>
                    <p>The project for Ethylene and Propylene Storage Spherical Tanks construction involved a comprehensive scope of work, from initial design and engineering to final certification. This project aimed to construct eight high-capacity spherical tanks to enhance the storage capabilities of the Steam Cracker Complex in RAPID Pengerang. Our collaboration with key consortium partners ensured the successful completion of the project, meeting all industry standards and client specifications.</p>
                    <h5 class="subtitle">Design and Engineering</h5>
                    <p>The design and engineering phase involved detailed planning to meet ASME and DOSH certification standards. Working closely with consortium partner, we developed comprehensive blueprints and specifications to ensure the structural integrity and safety of the spherical tanks.</p>
                    
                    <div class="row img-wrapper">
                        <div class="col-12 col-lg-11 mx-auto">
                            <p><img src=${epccDesignEngineering} alt="Design and Engineering" class="img-fluid"/></p>
                        </div>
                    </div>

                    <h5 class="subtitle">Procurement of Materials</h5>
                    <p>Securing high-quality materials and components was crucial for the construction of the storage tanks. This phase involved sourcing materials that met stringent industry standards and project specifications, ensuring the longevity and reliability of the tanks.</p>
                    
                    <div class="row img-wrapper">
                        <div class="col-12 col-lg-11 mx-auto">
                            <p><img src=${epccProcurementMaterials} alt="Procurement of Materials" class="img-fluid"/></p>
                        </div>
                    </div>

                    <h5 class="subtitle">Fabrication and Assembly</h5>
                    <p>The fabrication process involved cutting, shaping, and welding steel plates to form the spherical tank structure. This phase also included the on-site assembly of prefabricated components, ensuring precision and adherence to safety standards.</p>
                    
                    <div class="row img-wrapper">
                        <div class="col-12 col-lg-11 mx-auto">
                            <p><img src=${epccFabricationAssembly} alt="Fabrication and Assembly" class="img-fluid"/></p>
                        </div>
                    </div>

                    <h5 class="subtitle">Testing and Certification</h5>
                    <p>Once construction was complete, the tanks underwent rigorous testing to ensure structural integrity and safety. The final step involved obtaining ASME and DOSH certifications, confirming the tanks' readiness for operational use.</p>
                    
                    <div class="row img-wrapper">
                        <div class="col-12 col-lg-11 mx-auto">
                            <p><img src=${epccTestingCertification} alt="Testing and Certification" class="img-fluid"/></p>
                        </div>
                    </div>

                    <h5 class="subtitle">Conclusion</h5>
                    <p>The successful execution of this project underscores Persafe Engineering's capabilities in delivering high-quality construction services for the oil and gas industry. Our commitment to safety, efficiency, and excellence continues to drive our success in complex engineering projects.</p>
                    
                    <p>At Persafe, we thrive on collaboration, whether for local or international projects. No project is too small or too large for us to consider. We approach every opportunity with dedication and enthusiasm. For potential tenders or collaboration inquiries, please contact us.</p>`
		}]
	}
};
