import peruBalanDingProfile from '../assets/images/persafe/persafe-peru-balan-ding-2.jpg';
import jaredPeruProfile from '../assets/images/persafe/jared-e.jpg';
import sandraUsupProfile from '../assets/images/persafe/persafe-sandra-usup-2.png';
import noelImangProfile from '../assets/images/persafe/persafe-noel-imang-comp.png';

const techTeam = [];
/* {
	id: 'fistName',
	name: 'Firstname Lastname',
	position: 'Title',
	description: 'team.firstname.description',
	image: firstnameProfile
} */

const advisoryTeam = [];
/* {
	id: 'fistName',
	name: 'Firstname Lastname',
	position: 'Title',
} */

const businessTeam = [{
	id: 'peru-balan-ding',
	name: 'Peru Balan Ding',
	position: 'Founder, MD/CEO',
	description: 'team.peruBalanDing.description',
	image: peruBalanDingProfile,
	email: '' // 'peru.balan@persafe.com.my'
}, {
	id: 'jared-peru',
	name: 'Jared Peru',
	position: 'Executive Director',
	description: 'team.jaredPeru.description',
	image: jaredPeruProfile,
	email: '' // 'jared.peru@persafe.com.my'
}, {
	id: 'sandra-usup',
	name: 'Sandra Usup',
	position: 'General Manager',
	description: 'team.sandraUsup.description',
	image: sandraUsupProfile,
	email: '' // 'sandra.usup@persafe.com.my'
}, {
	id: 'noel-imang',
	name: 'Noel Imang',
	position: 'Technical & Operations Manager',
	description: 'team.noelImang.description',
	image: noelImangProfile,
	email: '' // 'noel.imang@persafe.com.my'
}];

const teamList = {
	techTeam, advisoryTeam, businessTeam
};

export default teamList;
