import '../styles/Footer.scss';

import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import Socials from '../components/Socials';
import constants from '../constants';

function FooterComponent() {
    const [init, setInit] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine); // Load only the slim version for efficiency
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    const particleOptions = useMemo(() => ({
        fpsLimit: 60,
		background: {
			color: {
					value: '#000000',
			},
		},
		fullScreen: {
			enable: false,
		},
        particles: {
            number: {
                value: window.innerWidth >= constants.mediumScreenSize ? 20 : 10,
            },
            color: {
                value: '#ffaa00',
            },
            shape: {
                type: 'polygon',
                stroke: {
                    width: 1,
                    color: '#ffaa00',
                },
            },
            opacity: {
                value: 0.5,
            },
            links: {
                enable: true,
                distance: 150,
                color: '#ffaa00',
                opacity: 0.4,
                width: 1,
            },
            size: {
                value: 2,
                random: true,
            },
            move: {
                enable: true,
                speed: 2,
                direction: 'none',
                outModes: {
                    default: 'out',
                },
                random: false,
                straight: false,
            },
        },
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: 'repulse',
                },
                onClick: {
                    enable: true,
                    mode: 'push',
                },
            },
            modes: {
                repulse: {
                    distance: 100,
                    duration: 0.4,
                },
                push: {
                    quantity: 4,
                },
            },
        },
        detectRetina: true,
    }), []);

    return (
        <footer className="footer">
            {init && (
                <Particles
                    id="tsparticles"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    options={particleOptions}
                    particlesLoaded={particlesLoaded}
                />
            )}
            <div className="col-md-8 mx-auto footer-content">
                <div className="row">
                    <h2 className="col-md-12 page-title white">
                        <FormattedMessage id="contact.title" />
                    </h2>
                    <p className="col-md-8 footer-contact">
                        <FormattedMessage
                            id="contact.entity"
                        />
                        <br />
                        {/*
                        <span className="footer-label">
                            <FormattedMessage
                                id="contact.label.country"
                            />
                        </span>
                        */}
                        <FormattedMessage
                            id="contact.address"
                        />
                        {' '}
                        <br />
                        <FormattedMessage
                            id="contact.address2"
                        />
                    </p>
                    <p className="col-md-8 footer-contact">
                        <span className="footer-label">
                            <FormattedMessage
                                id="contact.label.email"
                            />
                        </span>
                        <FormattedMessage
                            id="contact.email"
                        />
                    </p>
                    <p className="col-md-8 footer-contact">
                        <span className="footer-label">
                            <FormattedMessage
                                id="contact.label.phone"
                            />
                        </span>
                        <FormattedMessage
                            id="contact.phone"
                        />
                    </p>
                    <p className="col-md-8 footer-contact">
                        <span className="footer-label">
                            <FormattedMessage
                                id="contact.label.fax"
                            />
                        </span>
                        <FormattedMessage
                            id="contact.fax"
                        />
                    </p>
                    <div className="col-md-8 footer-about h-fade">
                        <p className="footer-about-text">
                            <small>
                                <FormattedMessage id="contact.description" />
                            </small>
                        </p>
                        <Socials
                            email="contact.email"
                            linkedin="contact.linkedin"
                        />
                    </div>
                    <p className="col-md-12 footer-copyright h-fade">
                        <FormattedMessage id="copyright.text" values={{ year: moment().year() }} />
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default FooterComponent;
