// import slide1 from '@/assets/images/approach-discovery.png';
// import slide2 from '@/assets/images/approach-design-thinking.png';
// import slide2 from '@/assets/images/persafe/excellence-culture.png';
// import slide3 from '@/assets/images/approach-scrum.png';
// import slide4 from '@/assets/images/approach-coach.png';

import slide1 from '../assets/images/persafe/cert-petros.jpg';
import slide2 from '../assets/images/persafe/cert-petronas.png';
import slide3 from '../assets/images/persafe/cert-cibd-1.png';
import slide4 from '../assets/images/persafe/cert-cibd-2.png';
import slide5 from '../assets/images/persafe/cert-upkj-1.jpg';
import slide6 from '../assets/images/persafe/cert-upkj-2.jpg';

const approachList = [{
	id: 1,
	title: {
		text: 'approach.slide1text',
		highlight: {
			highlight1: 'approach.slide1Highlight1',
			highlight2: 'approach.slide1Highlight2'
		}
	},
	image: slide1
}, {
	id: 2,
	title: {
		text: 'approach.slide2text',
		highlight: {
			highlight1: 'approach.slide2Highlight1',
			highlight2: 'approach.slide2Highlight2'
		}
	},
	image: slide2
}, {
	id: 3,
	title: {
		text: 'approach.slide3text',
		highlight: {
			highlight1: 'approach.slide3Highlight1',
			highlight2: 'approach.slide3Highlight2'
		}
	},
	image: slide3
}, {
	id: 4,
	title: {
		text: 'approach.slide4text',
		highlight: {
			highlight1: 'approach.slide4Highlight1',
			highlight2: 'approach.slide4Highlight2'
		}
	},
	image: slide4
}, {
	id: 5,
	title: {
		text: 'approach.slide5text',
		highlight: {
			highlight1: 'approach.slide5Highlight1',
			highlight2: 'approach.slide5Highlight2'
		}
	},
	image: slide5
}, {
	id: 6,
	title: {
		text: 'approach.slide6text',
		highlight: {
			highlight1: 'approach.slide6Highlight1',
			highlight2: 'approach.slide6Highlight2'
		}
	},
	image: slide6
}];

export default approachList;
