import '../styles/Socials.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import emailIcon from '../assets/images/social-mail.png';
import linkedinIcon from '../assets/images/social-linkedin.png';
// import facebookIcon from '../assets/images/social-facebook.png';

class SocialsComponent extends React.PureComponent {
	render() {
		// const { intl, email, linkedin, facebook } = this.props;
		const { intl, email, linkedin } = this.props;

		const socialLinks = [{
			type: 'email',
			href: `mailto:${intl.formatMessage({ id: email })}`,
			image: emailIcon
		}, {
			type: 'linkedin',
			href: intl.formatMessage({ id: linkedin }),
			image: linkedinIcon
		/*
		}, {
			type: 'facebook',
			href: intl.formatMessage({ id: facebook }),
			image: facebookIcon
		*/
		}];
		return (
			<div className="socials">
				{socialLinks.map(social => (
					<a href={social.href} key={social.type}>
						<img src={social.image} alt={social.type} />
					</a>
				))}
			</div>
		);
	}
}

/*
SocialsComponent.contextTypes = {
	intl: PropTypes.shape().isRequired
};
*/

SocialsComponent.propTypes = {
	intl: PropTypes.object.isRequired,
	email: PropTypes.string.isRequired,
	linkedin: PropTypes.string.isRequired,
	// facebook: PropTypes.string.isRequired
};

export default injectIntl(SocialsComponent);
