import '../styles/Intro.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Banner from '../components/Banner';
// import Bulb from '@/components/Bulb';
// import BulbVideo from '@/components/BulbVideo';
import IntroVideo from '../components/IntroVideo';

// import client1 from '../assets/images/client-grab-white.png';
// import client2 from '../assets/images/client-uex-white.png';
// import client3 from '../assets/images/client-rainbow-white.png';
// import client4 from '../assets/images/client-bnp-white.png';

function IntroComponent() {
	return (
		<div id="intro" className="has-header container">
			<Banner className="intro screen-height">
				<div className="intro-text">
					<div className="col-md-8 mx-auto">
						<h1 className="white text-huge header-regular intro-title">
							<FormattedMessage
								id="intro.title"
								values={{
									making: (
										<span className="intro-text-making">
											<FormattedMessage
												id="intro.making"
											/>
										</span>
									),
									ideas: (
										<span className="intro-text-ideas orange">
											<FormattedMessage
												id="intro.ideas"
											/>
										</span>
									)
								}}
							/>
						</h1>
						<div className="row">
							<p className="col-sm-12 col-md-6 col-lg-5 h-fade text-big intro-description">
								<FormattedMessage
									id="intro.description"
								/>
							</p>
						</div>
					</div>
				</div>
				{/*
				<div className="intro-clients">
					<div className="col-md-8 mx-auto">
						<p className="h-fader small">
							<span className="small">
								<FormattedMessage
									id="intro.clients"
								/>
							</span>
						</p>
						<ul className="intro-clients-list">
							<li>
								<img src={client1} alt="Grab" />
							</li>
							<li>
								<img src={client2} alt="UEX" />
							</li>
							<li>
								<img src={client3} alt="Rainbow Centre" />
							</li>
							<li>
								<img src={client4} alt="BNP Paribas" />
							</li>
						</ul>
					</div>
				</div>
				*/}
				{/* <Bulb className="intro-bg"></Bulb> */}
				{/* <BulbVideo /> */}
				<IntroVideo />
			</Banner>
		</div>
	);
}

IntroComponent.propTypes = {
};

export default IntroComponent;
