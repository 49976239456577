import '../styles/Gallery.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Animation from '../components/Animation';

const odd = 2;
const even = 3;

class GalleryComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			// gallery: []
			gallery: this.groupImages(props.images)
		};
	}
	
	componentDidUpdate(prevProps) {
		if (prevProps.images !== this.props.images) {
			console.log('Images changed, updating gallery...');
			this.updateGalleries(this.props.images);
		}
	}

    groupImages(images) {
        if (!images || images.length === 0) return []; // Handle empty case
        let oddRow = true;
        const result = [];
        const imageClone = images.slice(0);
        let id = 0;

        while (imageClone.length > 0) {
            id += 1;
            result.push({
                id,
                images: imageClone.splice(0, oddRow ? odd : even)
            });
            oddRow = !oddRow;
        }
        return result;
    }

	/*
	updateGalleries(images) {
		let oddRow = true;
		const result = [];
		const imageClone = images.slice(0);

		let id = 0;
		while (imageClone.length > 0) {
			id += 1;
			const rowImages = imageClone.splice(0, oddRow ? odd : even);
			console.log('Row ID:', id, 'Images:', rowImages);
			result.push({
				id,
				images: imageClone.splice(0, oddRow ? odd : even)
			});
			oddRow = !oddRow;
		}

		console.log('Setting gallery state:', result);
		this.setState({
			gallery: result
		});
	}
	*/

	render() {
		const { gallery } = this.state;

		return (
			<div className="gallery">
				<div className="gallery-container">
					{gallery.map((arr) => {
						// let className = arr.images.length === odd ? 'col-6 col-md-6' : arr.images.length === even ? 'col-4 col-md-4' : 'col-12 col-md-12';
						let className;
                        if (arr.images.length === odd) {
                            className = 'col-12 col-sm-6 col-md-6'; // Stack on XS, side-by-side on SM+
                        } else if (arr.images.length === even) {
                            className = 'col-12 col-sm-4 col-md-4'; // Stack on XS, side-by-side on SM+
                        } else {
                            className = 'col-12 col-md-12'; // Always full width
                        }
						return (
							<Animation key={arr.id} animate="fadeIn" once>
								{arr.images.map(image => <img key={image} className={className} src={image} alt="" />)}
							</Animation>
						);
					})}
				</div>
			</div>
		);
	}
}

GalleryComponent.propTypes = {
	images: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default GalleryComponent;
