import testimonialList from '../sources/testimonials';

export const FETCH_TESTIMONIALS = 'FETCH_TESTIMONIALS';

export function fetchTestimonials() {
	let newList = testimonialList;
	newList = [];
	return {
		type: FETCH_TESTIMONIALS,
		payload: newList // testimonialList
	};
}
